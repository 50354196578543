import React, { useEffect } from "react";

import { currentUserVar, useReactiveVar } from "@apollo";

// Misc imports

import { useTranslation } from "@i18n";
import { makeStyles } from "@styles";
import { navigate } from "@utils";

// Component imports
import {
  AppleLoginButton,
  DividerLineWithText,
  Error as ErrorComponent,
  FacebookLoginButton,
  Link,
  SignInForm,
} from "@components";
import { Grid, Paper, Typography } from "@material";

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: "auto",
    minHeight: 508,
    width: 372,
    border: "1px solid #DADADA",
    borderRadius: 8,
    margin: "auto",
    marginTop: 19,
    marginBottom: 56,
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      width: "90%",
      marginTop: 24,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  container: {
    width: 308,
    margin: "3px auto 25px auto",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      margin: "0px auto 16px auto",
    },
  },
  header: {
    marginTop: 16,
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      marginBottom: 8,
      fontSize: 18,
    },
  },
  textFieldWidth: {
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  signUpText: {
    textAlign: "center",
    color: "#464646",
    fontSize: 18,
    marginTop: 10,
    marginLeft: 8,
    marginRight: 8,
    [theme.breakpoints.down("xs")]: {
      marginTop: 8,
      fontSize: 16,
    },
  },
}));

export default function SignIn({ location }) {
  const classes: any = useStyles({});
  const { t } = useTranslation(["signIn", "common"]);

  const user = useReactiveVar(currentUserVar);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (user) navigate("/", { replace: true });
  }, []);

  const signInError: boolean = location?.state?.signInError || undefined;
  const prefilledEmail: string = location?.state?.prefilledEmail || undefined;

  return user ? null : (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: "68.25vh" }}
    >
      <Paper className={classes.paper}>
        <Grid
          container
          item
          direction="column"
          justifyContent="center"
          alignItems="center"
          xs={12}
          sm={10}
          className={classes.container}
        >
          <Grid item>
            <Typography className={classes.header}>{t("signIn")}</Typography>
          </Grid>
          <Grid className={classes.textFieldWidth}>
            {signInError && (
              <ErrorComponent>{t("common:errors.mongoError")}</ErrorComponent>
            )}
            <SignInForm prefilledEmail={prefilledEmail} />
            <DividerLineWithText text={t("common:or")} />
            <FacebookLoginButton />
            <AppleLoginButton />
          </Grid>
          <Grid>
            <Typography className={classes.signUpText} style={{ marginTop: 16 }}>
              {t("notMember")}{" "}
              <Link to="/sign-up">
                <strong>{t("joinCoinmiles")}</strong>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}
